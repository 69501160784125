.login .content-login .logo {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 25%;
  max-width: 260px; }

.login .content-login .card {
  min-width: 450px;
  width: 700px;
  max-width: 700px;
  min-height: 530px;
  border-radius: 46px;
  display: flex;
  align-items: center;
  background-color: #fff; }
  .login .content-login .card .header {
    font-size: 46px;
    font-weight: bold; }
    .login .content-login .card .header svg {
      display: none; }
  .login .content-login .card form .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 40px 20px; }
    .login .content-login .card form .body .form-control {
      background-color: #f2f2f2 !important;
      border: 1px solid #cecece;
      border-radius: 15px;
      height: auto;
      padding: 15px;
      margin: 10px 0; }
  .login .content-login .card form .footer {
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;
    padding: 20px 40px; }
    .login .content-login .card form .footer button {
      background: #fac81b 0% 0% no-repeat padding-box;
      font-size: 26px;
      letter-spacing: 1.3px;
      color: black;
      width: 276px;
      height: auto;
      padding: 10px 0;
      margin: auto;
      border-radius: 15px; }
      .login .content-login .card form .footer button .spinner-border {
        margin-right: 1rem;
        width: 1.5rem;
        height: 1.5rem; }

@media (max-width: 700px) {
  .login {
    overflow-y: hidden; }
    .login .content-login {
      display: grid;
      grid-template-rows: auto 1fr;
      overflow-x: scroll; }
      .login .content-login .logo {
        position: initial;
        margin: auto;
        margin-top: 15px;
        width: 200px; }
      .login .content-login .card {
        min-width: auto;
        width: 95%;
        max-width: 700px;
        min-height: auto;
        border-radius: 46px;
        display: flex;
        align-items: center;
        background-color: #fff;
        margin: auto; }
        .login .content-login .card .header {
          font-size: 38px;
          font-weight: bold; }
          .login .content-login .card .header svg {
            display: none; }
        .login .content-login .card form {
          display: block;
          width: 100%; }
          .login .content-login .card form .body {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px; }
            .login .content-login .card form .body .form-control {
              padding: 10px;
              margin: 10px 0; }
          .login .content-login .card form .footer {
            padding: 10px; }
            .login .content-login .card form .footer button {
              background: #fac81b 0% 0% no-repeat padding-box;
              font-size: 20px;
              width: 80%;
              height: auto;
              padding: 5px 0;
              margin: auto;
              border-radius: 15px; } }
