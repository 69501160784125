.btn-omb {
  height: 40px;
  border: none;
  border-radius: 5px;
  margin: 5px 5px;
}
.btn-omb:focus {
  border: none;
  outline: none;
}

.btn-omb-primary {
  background-color: var(--blue);
  color: #fff;
  border: none;
  border-radius: 5px;
}
.btn-omb-primary:hover {
  background-color: var(--blue-hover);
}
.btn-omb-primary-outline {
  background-color: transparent;
  color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 5px;
}
.btn-omb-primary-outline:hover {
  background-color: var(--blue-hover);
  color: #fff;
}
.btn-omb-yellow {
  background-color: var(--yellow);
  color: #000;
  border: none;
  border-radius: 5px;
  font-family: avenirDemi;
  font-weight: 500;
}
.btn-omb-yellow:hover {
  background-color: var(--yellow-hover);
}

.btn-omb-yellow-outline {
  background-color: transparent;
  color: var(--yellow);
  font-weight: bold;
  border: 1px solid var(--yellow);
  border-radius: 5px;
}
.btn-omb-yellow-outline:hover {
  background-color: var(--yellow);
  color: #000;
}
