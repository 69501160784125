.index-utils-create-search {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .index-utils-create-search.mt-15 {
    margin-top: 15px; }
  @media only screen and (max-width: 768px) {
    .index-utils-create-search {
      flex-direction: column;
      align-items: flex-start; } }
  .index-utils-create-search .container-btn {
    margin-bottom: 0; }
    @media only screen and (max-width: 768px) {
      .index-utils-create-search .container-btn {
        margin-bottom: 40px; } }
    .index-utils-create-search .container-btn .btn-create-membership {
      background-color: #51C8ED;
      padding: 15px;
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      max-width: 296px; }
      .index-utils-create-search .container-btn .btn-create-membership:hover {
        text-decoration: none; }
      .index-utils-create-search .container-btn .btn-create-membership .btn-create-membership--icon {
        margin-right: 5px; }
