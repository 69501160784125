.help-box {
  position: relative; }
  .help-box p {
    font-size: 18px; }
  .help-box .list {
    list-style: none;
    padding: 0 14px;
    margin: 20px 0; }
    .help-box .list li::before {
      content: '\2022';
      color: #4fc9ef;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em; }
  .help-box .options .radio {
    margin-right: 10px; }
  .help-box .options .label {
    margin-right: 10px; }
  .help-box .col-md-6 {
    position: inherit; }
  .help-box .buttons {
    position: absolute;
    right: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end; }
    .help-box .buttons button {
      width: 160px; }
      .help-box .buttons button:first-child {
        background: #dbdbdb;
        color: black; }

.text-purchased {
  font-size: 20px; }

@media (max-width: 720px) {
  .help-box {
    position: relative; }
    .help-box p {
      font-size: 18px; }
    .help-box .list {
      list-style: none;
      padding: 0 14px;
      margin: 20px 0; }
      .help-box .list li::before {
        content: '\2022';
        color: #4fc9ef;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em; }
    .help-box .options .radio {
      margin-right: 10px; }
    .help-box .options .label {
      margin-right: 10px; }
    .help-box .col-md-6 {
      position: inherit; }
    .help-box .buttons {
      position: initial;
      display: flex;
      justify-content: center; } }
