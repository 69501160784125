.box-text {
  background-color: #e5e5e5;
  padding: 15px 30px;
  margin-bottom: 20px;
  border-radius: 5px; }
  .box-text .text {
    margin: 0px; }

.body-coupon > .d-flex {
  align-items: center; }

.box-coupon {
  border: 1px solid #eeeeee; }
  .box-coupon .header-coupon {
    display: flex;
    justify-content: space-between;
    background-color: var(--blue);
    font-weight: 400;
    font-size: 34px;
    color: #fff; }
    .box-coupon .header-coupon .title {
      font-size: 34px;
      margin: 0; }
    .box-coupon .header-coupon button {
      font-size: 25px;
      margin: auto;
      margin-right: 15px;
      font-weight: normal;
      letter-spacing: 0.5px;
      padding: 6px 30px;
      height: auto;
      border-radius: 10px; }
  .box-coupon .body-coupon {
    padding: 40px;
    background-color: #fff; }
    .box-coupon .body-coupon .d-flex {
      align-items: center; }
    .box-coupon .body-coupon input {
      background-color: #efefef;
      border: none;
      font-size: 28px;
      color: #afafaf;
      margin: 0;
      text-indent: 9px;
      border-radius: 10px;
      font-family: avenirIt;
      font-weight: 600; }
    .box-coupon .body-coupon button {
      font-size: 29px;
      font-weight: 500;
      height: auto;
      padding: 0;
      margin: 0 15px;
      width: 50%; }
  .box-coupon .form-control {
    margin-top: 6px; }

.btn-gray {
  background-color: #e6e6e6;
  outline: none;
  border: none;
  height: 42px;
  width: 190px;
  border-radius: 5px; }

@media (max-width: 720px) {
  .box-coupon .header-coupon {
    display: grid;
    justify-content: normal;
    background-color: var(--blue);
    font-weight: 400;
    color: #fff; }
    .box-coupon .header-coupon .title {
      margin: auto; }
    .box-coupon .header-coupon button {
      margin: auto;
      margin-right: auto;
      font-weight: normal;
      letter-spacing: 0.5px;
      padding: 7px;
      height: auto;
      border-radius: 10px;
      margin-bottom: 15px; }
  .box-coupon .body-coupon {
    padding: 0; }
    .box-coupon .body-coupon .d-flex {
      display: grid !important;
      margin-top: 15px;
      grid-gap: 15px; }
    .box-coupon .body-coupon input {
      text-indent: 5px; }
    .box-coupon .body-coupon button {
      width: 60%;
      margin: auto; } }
