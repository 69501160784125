.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}
.main-panel {
  background: #f9f9f9;
  position: relative;
  float: right;
  width: calc(100% - 20%);
  max-width: calc(100% - 320px);
  height: 100%;
  /* transition: 0.5s; */
}

.main-panel > .content-app {
  padding: 15px;
  min-height: calc(100% - 123px);
  /* transition: margin-left .5s; */
}
.main-panel > .footer {
  border-top: 1px solid #e7e7e7;
}
.main-panel .navbar {
  margin-bottom: 0;
}

@media (max-width: 720px) {
  .main-panel {
    width: 100%;
    max-width: 100% !important;
  }
  .full-width {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .main-panel > .content-app {
    background-color: white;
  }
}
