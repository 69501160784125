.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px; }

.title {
  display: flex;
  padding: 10px 35px;
  background-color: var(--blue);
  font-weight: 500;
  font-size: 35px;
  color: #fff; }

@media (max-width: 726px) {
  .title {
    font-size: 20px;
    padding: 10px; } }
