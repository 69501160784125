.property-box{
    display:flex;
    justify-content: center;
    margin:10px 20px;
    max-width: 900px;
    border: 1px solid #CCCCCC;

}
.property-column{
    /* border:1px solid red; */
    justify-items: end;
    width: 300px;
}
.property-box  .property-first-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:10px;
}
.property-thumbnail{
    width: 150px;
    height: 150px;
}
.property-address{
    font-size: 24px;
    font-weight: bold;
}

@media(max-width:992px){
    .property-box{
       display: inline-block;
    }

}