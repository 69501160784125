.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  min-width: 320px;
  background-color: var(--blue);
  display: block;
  z-index: 100;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  transition: 0.3s;
  display: grid;
  grid-template-rows: auto 1fr; }
  .sidebar .logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 4;
    background-color: #fff; }
    .sidebar .logo a img {
      margin: 0;
      padding: 35px; }
    .sidebar .logo .container_image {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 255px; }
      .sidebar .logo .container_image .logo_omb {
        position: absolute;
        z-index: 1;
        width: 115px;
        top: 0;
        left: 0;
        margin-top: 10px;
        margin-left: 20px; }
      .sidebar .logo .container_image .logo_sponsor {
        position: absolute;
        z-index: 0; }
  .sidebar .sidebar-wrapper {
    position: relative;
    height: 100%;
    overflow: auto;
    width: 100%;
    z-index: 4; }
    .sidebar .sidebar-wrapper .items {
      position: relative;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr auto; }
      .sidebar .sidebar-wrapper .items .logout:hover {
        cursor: pointer; }
      .sidebar .sidebar-wrapper .items .logout a {
        margin: 0;
        cursor: pointer; }
  .sidebar li a {
    color: white;
    margin: 0 0 15px;
    padding: 10px 15px;
    padding-right: 0px;
    font-size: 20px;
    background: #41c8f2;
    display: flex;
    align-items: center; }
    .sidebar li a .content-item {
      margin: 0;
      padding: 0;
      display: inline-flex;
      align-items: center; }
      .sidebar li a .content-item .name {
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        font-weight: 500; }
      .sidebar li a .content-item .content-icon {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        display: inline-flex;
        align-items: center; }
        .sidebar li a .content-item .content-icon .icon {
          width: 100%;
          height: 100%; }
  .sidebar li.active a {
    background: white;
    color: #5e5e5e; }
  .sidebar li.active .content-item .content-icon {
    filter: invert(1);
    -webkit-filter: invert(1); }

@media (max-height: 800px) and (max-width: 600px) {
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    background-color: var(--blue);
    display: block;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: center center;
    overflow-y: scroll;
    transition: 0.3s;
    z-index: 100 !important;
    min-width: auto;
    display: grid; }
    .sidebar .logo {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: relative;
      z-index: 4;
      background-color: #fff; }
    .sidebar .sidebar-wrapper {
      position: relative;
      overflow: auto;
      width: 100%;
      z-index: 4; }
      .sidebar .sidebar-wrapper .items {
        position: relative; } }

@media only screen and (max-width: 992px) {
  .sidebar-close {
    width: 0;
    min-width: 0; } }

@media (max-height: 800px) {
  .sidebar .sidebar-wrapper::-webkit-scrollbar {
    box-shadow: 0px 3px 3px #ffffff;
    border-radius: 0; }
  .sidebar .sidebar-wrapper::-webkit-scrollbar-track {
    background: white;
    border-radius: 0; }
  .sidebar .sidebar-wrapper::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 0; }
  .sidebar .sidebar-wrapper .items {
    position: relative; } }

@media (max-height: 500px) {
  .sidebar .sidebar-wrapper .items {
    height: auto;
    position: relative; } }
