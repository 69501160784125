.box-property .sub-title {
  background-color: #e5e5e5;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 7px;
  align-items: center;
  margin-top: 2rem; }
  .box-property .sub-title img {
    width: 37px; }
