.property-content form {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px; }
  .property-content form .carrusel {
    padding: 8px;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    display: block; }
    .property-content form .carrusel img {
      height: 130px;
      width: 100% !important;
      margin: auto; }
    .property-content form .carrusel .carousel-indicators,
    .property-content form .carrusel .carousel-control-next,
    .property-content form .carrusel .carousel-control-prev {
      display: none; }

.property-content .form-label {
  white-space: pre; }

.property-content .type-column div {
  margin: 10px 0; }

.property-content button {
  margin-top: 30px; }

.property-content .btn-file-profile {
  background-color: #4fc9ef;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 15px;
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.img-carousel {
  width: 200px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  margin: 5px; }

.grid-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center; }
  .grid-images .box-img-add {
    position: relative; }
    .grid-images .box-img-add::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 5px;
      width: 200px;
      height: 250px;
      cursor: pointer; }
    .grid-images .box-img-add:hover::after {
      content: 'Add image';
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5); }
    .grid-images .box-img-add .img-add {
      width: 200px;
      height: 250px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
      margin: 5px; }
  .grid-images .box-img-add-remove {
    position: relative; }
    .grid-images .box-img-add-remove .img-add-remove {
      width: 200px;
      height: 250px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
      margin: 5px; }
    .grid-images .box-img-add-remove .ic-remove {
      position: absolute;
      top: 10px;
      right: 15px; }
  .grid-images #file-profile {
    display: none; }

.test-column,
.type-column {
  display: grid;
  grid-template-columns: 2fr 80px 1fr 100px 100px;
  grid-gap: 10px; }
  .test-column.type-column,
  .type-column.type-column {
    grid-template-columns: 3fr 2fr 2fr 2fr 2fr 2fr; }

@media only screen and (max-width: 720px) {
  .property-content form {
    display: block; } }

@media only screen and (max-width: 1200px) {
  .property-content .test-column,
  .property-content .type-column {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    grid-row-gap: 5px; } }
