.switch-box{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 10px;
    top: 30%;
    z-index: 1;
}
.switch-box .link{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.switch-box  .link:hover{
    color:#fff;
    text-decoration: none;
}
.switch-box .link .switch-button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background-color: var(--blue);
    cursor: pointer;
    transition: all .5s ease;
    color:#FFF;
    margin:2px 0px;
}

.switch-box  .link .switch-button .text{
    display: none;
    color:#fff;
    text-decoration: none;
    transition: all 1s ease;
}

.switch-box .switch-button:hover{
    width: 300px;
    justify-content:start;
    padding-left: 5px;
}

.switch-box .link .switch-button:hover .text{
    display: inline;
    color: #FFF;
    margin:0px 10px;
}