
.login{
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color:#333;
    background-image: url('../../../assets/img/bg-login.jpg');
    background-size: cover;
    background-position: center;
}
.content-login{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.header{
    display: flex;
    flex-direction: column;
    min-height: 120px;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.body button{
    width: 200px;
    margin:20px 0;
}
#forgot-text{
    display: flex;
    flex-direction: column;
}
#forgot-text a{
    text-align: end;
}

#singup-text{
    margin:20px;
    text-align: center;
}

.error{
    color: red;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 14px;
}