.Navbar {
  background-color: #51C8ED;
  height: 99px; }
  .Navbar .Navbar--componentName {
    color: #fff;
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 500; }
  .Navbar .section-icons {
    display: flex;
    margin: auto; }
    .Navbar .section-icons img {
      width: 40px;
      height: 40px;
      margin: 0 5px; }
      .Navbar .section-icons img:hover {
        cursor: pointer; }
    .Navbar .section-icons .ic-link {
      text-decoration: none; }
    .Navbar .section-icons .container-points {
      display: flex;
      position: relative; }
      .Navbar .section-icons .container-points .icon {
        position: relative;
        right: 0; }
      .Navbar .section-icons .container-points .point {
        visibility: visible;
        font-size: 12px;
        background-color: #fecc00;
        position: absolute;
        right: -30px;
        top: -15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        min-width: 50px;
        border-radius: 10%;
        -webkit-border-radius: 10%;
        -moz-border-radius: 10%;
        -ms-border-radius: 10%;
        -o-border-radius: 10%;
        margin: 0; }
      .Navbar .section-icons .container-points .tooltiptext {
        visibility: hidden;
        min-width: 60px;
        width: auto;
        background-color: #fecc00;
        color: #181f55;
        text-align: center;
        border-radius: 6px;
        padding: 2px 0;
        position: absolute;
        z-index: 1;
        right: -90%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 12px; }
        .Navbar .section-icons .container-points .tooltiptext::after {
          content: '';
          position: absolute;
          top: 40%;
          left: -4%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent #fecc00 transparent transparent; }
  .Navbar .nav__container {
    display: flex;
    align-items: center;
    justify-content: center; }
    .Navbar .nav__container .nav__container--link {
      color: #fff; }
    .Navbar .nav__container .nav__container--userName {
      color: #181F55;
      margin: 0;
      font-size: 26px;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 15px; }

@media (max-width: 767px) {
  .Navbar {
    height: auto; }
    .Navbar .Navbar--componentName {
      display: none; }
  .nav__container--userName {
    width: 100%;
    display: flex;
    grid-gap: 7px;
    padding: 0 15px;
    margin: auto;
    display: none; }
    .nav__container--userName .section-icons {
      display: flex; } }
