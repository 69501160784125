.invite-component .box-text {
  background-color: #e5e5e5;
  padding: 15px 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  margin-left: -20px;
  margin-right: -20px; }

.invite-component p {
  font-weight: 500; }
  .invite-component p strong {
    font-family: avenirBold !important;
    font-weight: bold !important; }

.invite-component .add-rederral {
  display: inline-block;
  color: #000;
  margin: 15px 0; }
  .invite-component .add-rederral:hover .tooltiptext {
    visibility: visible;
    opacity: 1; }

.invite-component .referral:first-child .remove-referral {
  display: none; }

.invite-component .remove-referral img {
  width: 35px; }

.invite-component .remove-referral:hover {
  cursor: pointer; }

.invite-component .btn-omb,
.invite-component .link-omb,
.invite-component .btn-omb-yellow {
  min-width: 200px; }

.invite-component .invited-icon-text {
  background-color: var(--blue-light);
  border-radius: 10px;
  width: 30px;
  margin: 0 7px; }

.invite-component .radio-type {
  margin-right: 10px; }

.invite-component .number-referral {
  display: inline-block;
  padding: 0 20px;
  height: 34px;
  padding-top: 3px;
  font-size: 20px;
  text-align: center;
  background-color: var(--blue-light);
  color: #fff;
  border-radius: 10px;
  margin-left: 15px; }

.invite-component .logo-footer {
  display: grid; }
  .invite-component .logo-footer img {
    width: 130px;
    margin: auto; }

.invite-component .containerAddRederral .add-rederral {
  text-decoration: none; }
  .invite-component .containerAddRederral .add-rederral:hover .tooltiptext {
    visibility: visible;
    opacity: 1; }

.invite-component .containerAddRederral .tooltiptext {
  visibility: hidden;
  width: 100%;
  margin-left: 10px;
  opacity: 0; }
