:root {
    --yellow: #FECC00;
    --yellow-hover: #e6ba04;
    --blue: #1A2353;
    --blue-hover:#242f6b;
    --blue-light: #51C8ED;
    --blue-bg-botton:#2090B2;
    --blue-bg-select:#2090B2;
    --gray:#F5f5f5;
    --gray-font:#4D4D4D;
    --green: #459628;
  }