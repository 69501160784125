.search-properties {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px; }
  .search-properties h3 {
    font-size: 30px;
    font-weight: 500;
    margin: auto;
    margin-top: 7px; }
  .search-properties .search {
    width: 50%;
    min-width: 400px; }
  @media (max-width: 600px) {
    .search-properties {
      grid-template-columns: auto; }
      .search-properties h3 {
        margin: auto;
        margin-left: 0; }
      .search-properties .search {
        width: auto;
        min-width: auto; } }
