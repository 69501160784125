.cardRecover {
  width: 700px;
  border-radius: 46px;
  display: flex;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0; }
  .cardRecover .header {
    text-align: center; }
    .cardRecover .header .titleCard {
      font-size: 38px;
      font-weight: bold;
      color: #000;
      margin-bottom: 0; }
    .cardRecover .header .spanCard {
      font-size: 20px;
      font-weight: normal;
      margin-top: 15px; }
      .cardRecover .header .spanCard.w-55 {
        width: 55%; }
  .cardRecover form .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0; }
    .cardRecover form .body .form-control {
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border: 1px solid #cecece;
      border-radius: 15px;
      height: auto;
      padding: 15px;
      margin: 30px auto;
      max-width: 300px; }
    .cardRecover form .body .mg-5 {
      margin: 5px auto !important; }
    .cardRecover form .body .message {
      margin: 0;
      padding: 0;
      color: red;
      font-size: 21px; }
  .cardRecover form .footer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr; }
    .cardRecover form .footer.only {
      grid-template-columns: 1fr;
      margin-top: 30px; }
    .cardRecover form .footer button {
      background: #fac81b 0% 0% no-repeat padding-box;
      font-size: 20px;
      letter-spacing: 0.58px;
      color: black;
      width: 200px;
      height: auto;
      padding: 10px 0;
      margin: auto;
      border-radius: 15px; }
      .cardRecover form .footer button:hover {
        cursor: pointer; }
    .cardRecover form .footer .back {
      margin: auto;
      padding: 0;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      letter-spacing: 0.58px; }
      .cardRecover form .footer .back .icon {
        font-weight: normal; }

@media (max-width: 700px) {
  .cardRecover {
    min-width: auto;
    width: 95%;
    max-width: 700px;
    min-height: auto;
    border-radius: 46px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: auto; }
    .cardRecover .header {
      font-size: 28px;
      font-weight: bold; }
      .cardRecover .header svg {
        display: none; }
    .cardRecover form {
      display: block;
      width: 100%; }
      .cardRecover form .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px; }
        .cardRecover form .body .form-control {
          padding: 10px;
          margin: 10px 0; }
      .cardRecover form .footer {
        padding: 10px; }
        .cardRecover form .footer button {
          background: #fac81b 0% 0% no-repeat padding-box;
          font-size: 20px;
          width: 80%;
          height: auto;
          padding: 5px 0;
          margin: auto;
          border-radius: 15px; } }
