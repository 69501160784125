.profile-container {
  width: 85%; }
  .profile-container p,
  .profile-container label {
    font-size: 16px;
    font-weight: 600; }
  .profile-container p {
    font-size: 16px !important; }
  .profile-container .row {
    margin-top: 20px; }
    .profile-container .row .btn-omb {
      margin: auto;
      margin-right: 0;
      margin-bottom: 5px;
      font-size: 23px;
      padding: 0 30px; }
  .profile-container .head {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 30px; }
    .profile-container .head .box-image-profile {
      position: relative; }
      .profile-container .head .box-image-profile:after {
        content: 'Change Photo';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 0;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 5px; }
      .profile-container .head .box-image-profile:hover:after {
        display: flex; }
      .profile-container .head .box-image-profile .ic-edit {
        display: none !important; }
    .profile-container .head #image-profile {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
      max-height: 209px;
      max-width: 209px; }
      .profile-container .head #image-profile .ic-edit {
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 1; }
    .profile-container .head .info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px; }
  .profile-container .second-row {
    padding-top: 20px;
    padding-bottom: 5px; }
  .profile-container .custom-file-label::after {
    background-color: var(--blue);
    color: #fff; }
  .profile-container #file-profile {
    display: none; }
  .profile-container .btn-file-profile {
    background-color: var(--blue-light);
    border-radius: 5px;
    height: 40px;
    width: 80%;
    text-align: center;
    color: #fff;
    padding-top: 5px;
    margin: 5px auto 0;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
  .profile-container .input-radio {
    margin-left: 10px; }

.stateOfIssuance {
  background: #efefef;
  border: 1px solid #ced4da; }

@media (max-width: 767px) {
  .profile-container {
    width: 100%; }
    .profile-container .row .btn-omb {
      margin: auto;
      margin-right: auto;
      margin-top: 15px; }
    .profile-container .head {
      grid-template-columns: auto; }
    .profile-container .box-image-profile {
      margin-bottom: 20px; }
    .profile-container .content-item {
      border-bottom: 1px solid;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

@media (max-width: 726px) {
  .box-image-profile {
    width: 80%;
    margin: auto; }
    .box-image-profile #image-profile {
      width: 100%;
      margin: auto; } }
